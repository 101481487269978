export const nonDhmeTasksTemplate = [
  {
    friendly_id: '1',
    title: 'Commercie',
    options: {
      type: 'dhme-init-phase',
    },
    weight: 2,
  },
  {
    friendly_id: '2',
    title: 'Ontwerpfase',
    options: {
      type: 'dhme-definitive-design-phase',
    },
    weight: 14,
    predecessors: ['1'],
  },
  {
    friendly_id: '3',
    title: 'Uitwerken definitief ontwerp',
    options: {
      type: 'dhme-definitive-design-phase',
    },
    weight: 28,
    predecessors: ['2'],
  },
  {
    friendly_id: '4',
    title: 'Omgevingsvergunning bouwen (Goedkeuring & inzage)',
    options: {
      type: 'dhme-environmental-permit-phase',
    },
    weight: 98,
    predecessors: ['3'],
  },
  {
    friendly_id: '5',
    title: 'Uitwerken technisch ontwerp (engineering)',
    options: {
      type: 'dhme-production-drawing-phase',
    },
    weight: 56,
    predecessors: ['4'],
  },
  {
    friendly_id: '6',
    title: 'Fabriek',
    options: {
      type: 'dhme-factory-phase',
    },
    weight: 14,
    predecessors: ['5'],
    hasChildren: true,
  },
  {
    friendly_id: '600',
    title: 'Produceren',
    options: {
      type: 'dhme-production-phase',
    },
    weight: 7,
    parent: '6',
  },
  {
    friendly_id: '601',
    title: 'Assemebleren',
    options: {
      type: 'dhme-assembly-phase',
    },
    weight: 7,
    parent: '6',
  },
  {
    friendly_id: '7',
    title: 'Realisatie op locatie',
    options: {
      type: 'dhme-construction-site-phase',
    },
    weight: 2,
    predecessors: ['6'],
    hasChildren: true,
  },
  {
    friendly_id: '10',
    title: 'Afmonteren & woonrijp',
    options: {
      type: 'dhme-install-assemble-finish-phase',
    },
    weight: 42,
    predecessors: ['7'],
  },
];
